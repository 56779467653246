<template>
  <Layout>
    <ConfirmaInscricao />
  </Layout>
</template>

<script>
import ConfirmaInscricao from "../components/confirma";
import Layout from "../components/layout";

export default {
  name: "confirma-inscricao",
  components: {Layout, ConfirmaInscricao}
}
</script>

<style scoped>

</style>
