var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', [_c('div', {
    staticClass: "navbar flex items-center justify-center relative z-10"
  }, [_c('nav', {
    staticClass: "flex flex-wrap items-center justify-between w1200 py-4 md:py-0 px-4 text-lg"
  }, [_vm._m(0), _c('svg', {
    staticClass: "h-6 w-6 cursor-pointer md:hidden block",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "id": "menu-button",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    },
    on: {
      "click": _vm.openMenu
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M4 6h16M4 12h16M4 18h16"
    }
  })]), _c('div', {
    staticClass: "hidden w-full md:flex md:items-center md:w-auto",
    attrs: {
      "id": "menu"
    }
  }, [_c('ul', {
    staticClass: "menu-itens md:flex md:justify-between md:pt-0 lg:m-3 md:px-10 mt-4"
  }, [_vm._m(1), _c('li', [_c('a', {
    staticClass: "item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-2 border-current/50 block",
    attrs: {
      "href": "#sobre"
    },
    on: {
      "click": _vm.closeMenu
    }
  }, [_vm._v("O evento")])]), _c('li', [_c('a', {
    staticClass: "item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-2 border-current/50 block",
    attrs: {
      "href": "#local"
    },
    on: {
      "click": _vm.closeMenu
    }
  }, [_vm._v("Local")])]), _vm._m(2)]), _c('button', [_c('a', {
    staticClass: "hidden md:block button-subscribe py-2 md:px-10 md:py-2 block rounded-md items-center",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openModalForm();
      }
    }
  }, [_vm._v("INSCREVA-SE")])])])])]), _vm._m(3), _c('nav', {
    staticClass: "nav-inscricao-mobile fixed bottom-0 inset-x-0 justify-between text-sm text-white uppercase font-bold block z-[99999] md:hidden lg:hidden",
    attrs: {
      "id": "inscricao-mobile"
    }
  }, [_c('a', {
    staticClass: "w-full block py-4 px-3 text-center font-bold transition duration-300",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openModalForm();
      }
    }
  }, [_vm._v(" Quero me inscrever ")])])]), _c('main', {
    staticClass: "flex justify-center"
  }, [_c('Content', {
    on: {
      "openModal": _vm.openModalForm
    }
  })], 1), _c('Drawer', {
    attrs: {
      "align": 'right',
      "closeable": true,
      "maskClosable": false,
      "zIndex": 100002
    },
    on: {
      "close": _vm.openModalForm
    }
  }, [_vm.openRegister ? _c('div', [_c('div', {
    staticClass: "cursor-pointer text-gray-600 w-full items-center border-b border-t-neutral-700 border-solid px-2 pb-4"
  }, [_c('h2', {
    staticClass: "text-sm font-semibold text-gray-900",
    attrs: {
      "id": "slide-over-title"
    }
  }, [_vm._v("INSCRIÇÃO - INFORMAÇÕES PESSOAIS")])]), _c('Register')], 1) : _vm._e()]), _c('section', {
    staticClass: "bg-white"
  }, [_c('div', {
    staticClass: "max-w-screen-xl px-4 py-2 mx-auto space-y-4 overflow-hidden sm:px-6 lg:px-8"
  }, [_c('p', {
    staticClass: "mt-8 text-base leading-6 text-center text-gray-400"
  }, [_vm._v(" © 2024 Nuvemshop Next. Todos os direitos reservados ")]), _c('div', {
    staticClass: "flex justify-center items-center gap-2.5"
  }, [_c('p', {
    staticClass: "font-inter text-sm font-normal text-center text-gray-500 opacity-80"
  }, [_vm._v("Feito com 💖 por:")]), _c('svg', {
    attrs: {
      "width": "11",
      "height": "15",
      "viewBox": "0 0 11 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.22731 0.432367L7.04939 4.37495C7.28865 4.53746 7.42258 4.84294 7.42258 5.22666C7.42258 5.61039 7.28865 5.96402 7.04939 6.2093L1.22731 12.1984C0.974504 12.4587 0.678058 12.5219 0.416223 12.3715C0.154387 12.221 0.00390625 11.9005 0.00390625 11.4957V1.56398C0.00390625 1.15918 0.154387 0.784489 0.416223 0.543721C0.678058 0.301448 0.974504 0.26082 1.22731 0.432367Z",
      "fill": "url(#paint0_linear_2283_7250)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.66502 2.73508C4.29843 4.39036 6.93183 6.04564 9.56523 7.70092C9.89027 7.90557 10.0724 8.23513 10.0724 8.61885C10.0724 9.00258 9.89027 9.33213 9.56523 9.53678C6.93183 11.1921 4.29843 12.8473 1.66502 14.5026C1.32193 14.7178 0.918645 14.7298 0.565017 14.5342C0.209884 14.3386 0.00523055 13.991 0.00523055 13.5862V2.00374C-0.00078866 1.55983 -0.0218574 1.143 0.212892 0.796891C0.480746 0.399623 0.906606 0.137788 1.35654 0.520008C0.267065 1.23178 1.20606 2.46722 1.66502 2.73658V2.73508Z",
      "fill": "url(#paint1_linear_2283_7250)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.66458 2.73467C4.29798 4.38995 6.93138 6.04523 9.56479 7.70051C9.84317 7.87507 10.0162 8.14142 10.0599 8.45442C8.5039 9.58603 6.43028 10.2767 4.15201 10.2767C2.65022 10.2767 1.23872 9.97577 0.00327715 9.44759V2.00032C-0.00274206 1.55641 -0.0238085 1.13958 0.210941 0.793473C0.478795 0.396205 0.904655 0.13437 1.35459 0.51659C0.265114 1.22836 1.20411 2.4638 1.66308 2.73316L1.66458 2.73467Z",
      "fill": "url(#paint2_linear_2283_7250)"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_2283_7250",
      "x1": "3.37316",
      "y1": "7.61629",
      "x2": "-1.64235",
      "y2": "5.22366",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FBAD18"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#EE2624"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_2283_7250",
      "x1": "3.56108",
      "y1": "6.14345",
      "x2": "-3.40315",
      "y2": "9.92201",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#009EC0"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#0054A1"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint2_linear_2283_7250",
      "x1": "1.79399",
      "y1": "5.27778",
      "x2": "9.97861",
      "y2": "7.24456",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#00A5B7"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#00589A"
    }
  })], 1)], 1)])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "mr-3 h-12 sm:h-14",
    attrs: {
      "src": require("../assets/logo.png"),
      "alt": "logotipo"
    }
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-2 border-current/50 block",
    attrs: {
      "href": "/"
    }
  }, [_vm._v("Home")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-[1px] border-current/50 block",
    attrs: {
      "href": "#palestrantes"
    }
  }, [_vm._v("Palestrantes")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "header-top"
  }, [_c('div', {
    staticClass: "header-container"
  }, [_c('div', {
    staticClass: "header-back"
  })]), _c('figure', {
    staticClass: "event-cover header-back"
  })]);
}]

export { render, staticRenderFns }