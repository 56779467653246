<template>
  <Layout>
    <Content />
  </Layout>
</template>

<script>
import Content from "../components/content";
import Layout from "../components/layout";

export default {
  name: "home-view",
  components: {Layout, Content}
}
</script>

<style scoped>

</style>
