<template>
  <main>
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <section class="flex h-full flex-col overflow-hidden" v-if="showError">
      <div class="flex-1 overflow-hidden py-6">
        <div class="subtitle-register px-2">
          <h1 class="text-md font-medium text-gray-600"><b>{{ participant.name }},</b></h1>
        </div>
        <div class="subtitle-register px-2 mt-5">
          <h1 class="text-sm font-bold text-red-600">AÇÃO NÃO PERMITIDA!</h1>
        </div>
        <div class="py-4 px-2 mt-4 bg-red-300 border-2 border-red-500 rounded-lg ml-2 mr-2">
          <div class="px-2">
            <h1 class="text-sm font-normal mb-1 text-red-900">Identificamos que você já enviou a confirmação do termo de compromisso.</h1>
            <p class="mt-4 text-sm text-red-900">Qualquer dúvida, você pode entrar em contato conosco através do email <b>arcosuporte@fluireventos.com.br</b> ou através do nosso número de <b>Whatsapp (11) 98899-9900</b></p>
          </div>
        </div>
      </div>
    </section> 
    <section class="flex h-full flex-col overflow-hidden" v-if="showPayment">
      <div class="flex-1 overflow-hidden py-6">
        <div class="subtitle-register px-2">
          <h1 class="text-md font-medium text-gray-600">Olá, <b>{{ participant.name }}</b></h1>
          <h1 class="mt-5 text-sm">A sua vaga em nosso evento está quase garantida, confirme as informações de pagamento.</h1>
        </div>
        <div class="subtitle-register px-2 mt-10">
          <h1 class="text-sm font-bold text-gray-600">INGRESSOS DISPONÍVEIS</h1>
        </div>
        <div class="py-4 px-2 mt-4 bg-white border-2 border-gray-300 rounded-lg ml-2 mr-2">
          <div class="px-2">
            <h1 class="text-616161 text-sm font-bold mb-1">Arco Day 2024 - Cortesia</h1>
            <div class="flex gap-2">
              <h1 class="text-616161 text-sm font-medium text-red-600 line-through">R$ 1.500,00</h1>
              -
              <h1 class="text-616161 text-sm font-medium text-green-600">R$ 0,00</h1>
            </div>
            <div class="flex justify-between items-center mt-4">
              <h1 class="text-xs font-normal text-gray-600">Expira em 4 dias</h1>
              <div class="p-2 bg-gray-100 border-2 border-gray-300 rounded-md text-sm">
                CUPOM APLICADO
              </div>
            </div>
          </div>
        </div>
        <div class="absolute inset-x-0 bottom-10 p-3">
          <div class="flex justify-between items-center mt-10 border-t-2 px-2">
            <h1 class="text-sm font-normal text-gray-600 mt-4">Arco Day 2024 - Cortesia</h1>
            <h1 class="text-616161 text-sm font-medium mt-4">R$ 1.500,00</h1>
          </div>
          <div class="flex justify-between items-center mt-2 px-2">
            <h1 class="text-sm font-normal text-gray-600">Cupom #ARCO100{{ cupomRandom }}</h1>
            <h1 class="text-616161 text-sm font-medium">100%</h1>
          </div>
          <div class="flex justify-between items-center mt-2 px-2">
            <h1 class="text-sm font-bold text-gray-600">Total a pagar</h1>
            <h1 class="text-616161 text-sm font-bold">R$ 0,00</h1>
          </div>
          <div class="next-form py-3 mt-5">
            <button
              type="button"
              @click="stepTwo()"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-175376 rounded-lg focus:shadow-outline">
              <span class="flex justify-center mr-2">Prosseguir</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="flex h-full flex-col overflow-hidden" v-if="showTermo">
      <form @submit.prevent="submitTermo" class="form" autocomplete="off">
        <div class="flex-1 overflow-hidden py-6 px-2">
          <div class="subtitle-register">
            <h1 class="text-md font-medium text-gray-600"><b>{{ participant.name }},</b></h1>
            <h1 class="mt-5 text-sm">Para finalizar a sua inscrição, agora precisamos que você confirme os próximos passos.</h1>
          </div>
          <div class="mt-10">
            <h1 class="text-sm font-bold text-gray-600">Termo de compromisso.</h1>
          </div>
          <div class="py-4 mt-4 bg-white border border-2 border-gray-300 rounded-lg">
            <div class="px-4 py-1 max-h-40 overflow-auto text-xs">
              <span class="text-xs text-justify mb-2">
                <p>TERMO DE COMPROMISSO DE COMPARECIMENTO AO EVENTO ARCO DAY</p>
                <br>
                <p>Por favor, leia atentamente os termos e condições abaixo estipulados para que você possa usufruir do ARCO DAY. O ARCO DAY é oferecido pela COMPANHIA BRASILEIRA DE EDUCAÇÃO E SISTEMAS DE ENSINO S.A. (“ARCO EDUCAÇÃO”).</p>
                <br>
                <p>Ao confirmar sua participação, a ESCOLA PARCEIRA ou CONVIDADO aceita as seguintes condições:</p>
                <br>
                <h2 class="font-bold">DO EVENTO</h2>
                <br>
                <ul>
                    <li>O ARCO DAY é um evento promovido pela ARCO EDUCAÇÃO, destinado exclusivamente às escolas parceiras ou convidadas nominalmente pela empresa. O objetivo do evento é revolucionar o mercado educacional com palestras de especialistas nacionais e internacionais que vão proporcionar aprendizado, networking, insights e descobertas.</li>
                    <br>
                    <li>O evento será realizado no WTC Events – Golden Hall com endereço na Avenida das Nações Unidas, nº 12551, Brooklin Novo, São Paulo – SP nas datas de 18 e 19 de junho. No dia 18 o evento será realizado no período das 08h às 19h e no dia 19 no período das 08h às 14h.</li>
                </ul>
                <br>
                <h2 class="font-bold">DO COMPROMISSO DE COMPARECIMENTO</h2>
                <br>
                <ul>
                    <li>A ESCOLA PARCEIRA ou CONVIDADO se compromete a garantir a participação de seu representante no ARCO DAY, mediante a inscrição nominal, realizada previamente no website (<a href="https://arcoday.eventfy.live/">https://arcoday.eventfy.live/</a>).</li>
                    <br>
                    <li>A justificativa para eventual desistência deverá ser formalmente comunicada à ARCO EDUCAÇÃO através do e-mail <a href="mailto:arcoday@arcoeducacao.com.br">arcoday@arcoeducacao.com.br</a> para que sua vaga possa ser alocada para uma nova ESCOLA PARCEIRA ou CONVIDADO.</li>
                </ul>
                <br>
                <h2 class="font-bold">DO PAGAMENTO</h2>
                <br>
                <ul>
                    <li>O valor referente ao ingresso é de R$ 1.500,00 (mil e quinhentos reais), podendo a ARCO EDUCAÇÃO optar por oferecer gratuidade à ESCOLA PARCEIRA ou CONVIDADO, que será devidamente demonstrado no ato da inscrição;</li>
                    <br>
                    <li>Toda a logística e alimentação, bem como qualquer outro custo de consumo, deverá ser arcado pela ESCOLA PARCEIRA ou CONVIDADO.</li>
                </ul>
                <br>
                <h2 class="font-bold">DAS DISPOSIÇÕES GERAIS</h2>
                <br>
                <ul>
                    <li>Este Termo de Compromisso entra em vigor no ato da inscrição para o ARCO DAY e é válido até o término do evento.</li>
                    <br>
                    <li>O tratamento dos dados pessoais coletados dos visitantes do website do ARCO DAY e daqueles que se inscreverem e participarem do evento se dará nos termos da <a href="https://dpo.privacytools.com.br/api/policy/pdf/dczJJdxMNONPCBrINjyTVh08EveZXzbKJQQPTIoe4xAIhgflpuxYqsodFpxFJVAb2mA48pUERLyXh4kUVS9RXQ__equal____equal__" target="_blank">Declaração de Privacidade do ARCO DAY</a>.</li>
                    <br>
                    <li>A ESCOLA PARCEIRA ou CONVIDADO declara estar ciente de todas as condições estabelecidas neste Termo e concorda em cumpri-las integralmente.</li>
                </ul>
            </span>
            </div>
          </div>
          <div class="flex mb-6 mt-6 items-center">
            <label for="authorizes_data_usage" class="ml-2 text-sm font-normal text-gray-500">Ao clicar em <b>CONCLUIR</b> eu declaro que li e concordo com os termos de compromisso</label>
          </div>
          <div class="absolute inset-x-0 bottom-10 p-3">
            <div class="next-form py-3 mt-5">
              <button
                type="submit"
                class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-175376 rounded-lg focus:shadow-outline">
                <span class="flex justify-center mr-2">CONCLUIR</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
    <section class="flex justify-center" v-if="showThanks">
      <div class="w-full sm:w-full lg:w-full xl:w-full pt-10">
        <div class="px-2 pt-2">
          <div class="flex justify-center mb-4">
            <img class="max-h-44 max-w-60 rounded-lg" src="../assets/sucess.png">
          </div>
          <div class="tittle mb-2 px-4 text-center">
            <h1 class="text-3259ce text-md font-bold mb-4">{{ participant.name }},</h1>
            <h1 class="text-616161 text-sm font-semibold">Parabéns! Sua inscrição foi concluída com sucesso. Em breve, você receberá seu QR CODE de acesso e orientações gerais do evento.</h1>
          </div>
          <div class="py-2 mt-12 bg-white border-2 border-gray-200 rounded-lg">
            <div class="mb-2 text-center px-4">
              <h1 class="text-616161 text-sm font-bold mb-4">I M P O R T A N T E</h1>
              <h1 class="text-616161 text-sm font-normal">Fique atento ao seu e-mail de inscrição, pois é por meio dele que você receberá todas as informações necessárias.</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import {sendTermo} from "@/services/api/register";
import {notification} from "@/services/notification";

export default {
  name: "termo",
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  components: { Loading },
  directives: {
    mask: VueMaskDirective
  },
  data: () => ({
    open: false,
    mask: ['(', /\d/, /\d/, ') ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    loading: true,
    confirmTerm: null,
    submitted: false,
    showTermo: false,
    showPayment: true,
    showError: false,
    showThanks: false,
    cupomRandom: ((Math.random() + 1).toString(36).substring(8)).toUpperCase(),
    form: {
    }
  }),
  mounted () {
    this.loading = false;
    if (this.participant.status !== 2 || this.participant.ref_status !== 4) {
      this.showError = true;
      this.showPayment = false;
      this.$emit('headerDrawerTermo', 'FALHA AO CONFIRMAR');
    }
  },
  methods: {
    stepTwo () {
      this.loading = true;
      setTimeout(() => {
        this.$emit('headerDrawerTermo', 'INSCRIÇÃO - CONFIRMAÇÃO TERMOS DE COMPROMISSO');
        this.showPayment = !this.showPayment;
        this.showTermo = true;
        this.loading = false;
      }, "1000");

    },
    async submitTermo () {
      try {
        this.loading = true;
        let resp;
        this.submitted = true
        this.loading = true;

        const formData = new FormData()
        formData.append('id', this.participant.id)
        formData.append('confirmTerm', true)
        resp = await sendTermo(formData);
        if (resp.terms) {
          this.$emit('headerDrawerTermo', 'INSCRIÇÃO CONCLUÍDA');
          this.showTermo = false;
          this.showThanks = true;
        }
        this.loading = false;
      } catch (err) {
        notification.error(err?.errMsg ?? 'Houve um problema para concluir inscrição');
        this.loading = false;
        return;
      }
    }
  },
}
</script>

<style scoped>
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}
.bg-inactive {
  background: #ededed;
}
</style>
