import fetchNewRegister from "./fetch/fetchNewRegister";
import fetchNewAereoTermo from "./fetch/fetchNewAereoTermo";
import fetchNewTermo from "./fetch/fetchNewTermo";

async function apiFetch(form1Data) {
    if (!form1Data) {
        throw {cod: 'VPN7D2VT'};
    }
    let formData = makeForm(form1Data);
    let resp;
    resp = await fetchNewRegister(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        if (errRespBody.errors && errRespBody.errors.email[0] === "O campo email já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O e-mail cadastrado já encontra-se em uso, confira sua caixa de e-mails"};
        }
        if (errRespBody.errors && errRespBody.errors.cpf[0] === "O campo CPF já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O CPF cadastrado já encontra-se em uso"};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data && !respBody.user) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
    // return formData.get('name');
}

async function apiFetchAereo(formData) {
    if (!formData) {
        throw {cod: 'VPN7D2VT'};
    }
    let resp;
    resp = await fetchNewAereoTermo(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
}

async function apiFetchTermo(formData) {
    if (!formData) {
        throw {cod: 'VPN7D2VT'};
    }
    let resp;
    resp = await fetchNewTermo(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
}

function makeForm(inputData) {
    let formData = new FormData();
    formData.append('authorizes_data_usage', 1);
    formData.append('state', inputData.state.value);
    formData.append('city', inputData.city.nome);
    formData.append('phone', inputData.phone);
    formData.append('name', inputData.name);
    formData.append('email', inputData.email);
    formData.append('company', inputData.company);
    formData.append('role', inputData.role);
    formData.append('url_company', inputData.url_company);
    formData.append('client_nuvemshop', inputData.client_nuvemshop.value);
    if (inputData.client_nuvemshop.value === 'Não') {
        formData.append('plataform_client', inputData.plataform_client ? inputData.plataform_client.value : null);
        formData.append('plataform_client_other', inputData.plataform_client_other);
        formData.append('revenue_plataform', inputData.revenue_plataform ? inputData.revenue_plataform.value : null);
    }

    return formData;
}
export async function register(form1Data) {
    if (!form1Data) {
        throw '5KJM357A';
    }
    console.log(form1Data);


    return await apiFetch(form1Data);
}


export async function sendAereoFile(formData) {
    if (!formData) {
        throw '5KJM357A';
    }
    console.log(formData);


    return await apiFetchAereo(formData);
}


export async function sendTermo(formData) {
    if (!formData) {
        throw '5KJM357A';
    }
    console.log(formData);


    return await apiFetchTermo(formData);
}

