async function buscaCityByUF(uf) {
    const ufBusca = (uf + '').replace(/[^a-zA-Z]/g, '').slice(0, 2);
    const resp = await fetch(`https://brasilapi.com.br/api/ibge/municipios/v1/${ufBusca}`);
    if (!resp.ok) {
        throw {cod: '27GI4NHV'};
    }
    const respBody = await resp.json();

    if (respBody.erro) {
        throw {cod: 'ZJN554BB'};
    }

    return {
        state: ufBusca,
        cities: respBody,
    };
}

export const consultaCities = (data) => buscaCityByUF(data);
