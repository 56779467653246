import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home';
import ConfirmaInscricao from '../views/confirmainscricao';
import ConfirmaTerms from '../views/confirmaterms';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home
  },
  {
    path: '/confirmacao-inscricao',
    component: ConfirmaInscricao
  },
  {
    path: '/confirmacao-terms',
    component: ConfirmaTerms
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
