<template>
  <div>
    <Content />
    <Drawer
      @close="openModalAereoTermo"
      :align="'right'"
      :closeable="true"
      :maskClosable="false"
      :zIndex="100002"
    >
      <div v-if="openAereoTermo">
        <div class="cursor-pointer text-gray-600 w-full items-center border-b border-t-neutral-700 border-solid px-2 pb-4">
          <h2 class="text-sm font-semibold text-gray-900" id="slide-over-title">{{ titleAereoTermo }}</h2>
        </div>
        <AereoTermo :participant="participant" @headerDrawer="updateTitle"/>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Drawer from "vue-simple-drawer";
import AereoTermo from './aereotermo.vue';
import Content from './content.vue';
import {identify} from '../services/api/identify';
import {notification} from "@/services/notification";

export default {
  name: "confirma-inscricao",
  components: {
    Drawer,
    Content,
    AereoTermo
  },
  data: () => ({
    openAereoTermo: false,
    participant: {},
    titleAereoTermo: 'INSCRIÇÃO - PAGAMENTO'
  }),
  methods: {
    openModalAereoTermo () {
      this.openAereoTermo = !this.openAereoTermo;
    },
    updateTitle (title) {
      this.titleAereoTermo = title;
    },
    async getParticipant (uuid) {
      try {
        let resp;
        resp = await identify(uuid);
        this.participant = resp.resp;
        this.openModalAereoTermo();
      } catch (err) {
        notification.error(err?.errMsg ?? 'Houve um problema para concluir inscrição');
        console.log(err)
        return;
      }
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('identify') && urlParams.get('identify')) {
      this.getParticipant(urlParams.get('identify'));
    }
  },
}
</script>
<style scoped lang="scss">
</style>
