import {fetchParticipant} from "./fetch/fetchParticipant";
import {fetchActivitiesSave} from "./fetch/fetchActivities";

function failResp(message) {
    return {
        ok: false,
        message
    };
}

function successResp(resp) {
    return {
        ok: true,
        resp,
    };
}

async function apiFetch(identify) {
    const resp = await fetchParticipant(identify);
    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'HPV7FLW6'};
        }
        if (resp.status === 401 && errRespBody.message) {
            throw {cod: 'TJTFUAQ3', errMsg: errRespBody.message};
        }
        throw {cod: '12AUVU58'};
    }
    const respBody = await resp.json();

    if (respBody) {
        return successResp(respBody);
    }
    throw 'ERR';
}

async function apiFetchSave(form1Data) {
    if (!form1Data) {
        throw {cod: 'VPN7D2VT'};
    }
    let formData = makeForm(form1Data);
    let resp;
    resp = await fetchActivitiesSave(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data && !respBody.user) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
    // return formData.get('name');
}


function makeForm(inputData) {
    let formData = new FormData();
    formData.append('participant_id', inputData.participant_id);
    formData.append('tags', inputData.tags);

    return formData;
}

export async function identify(identify) {
    try {
        return await apiFetch(identify);
    } catch ({errMsg}) {
        return failResp(errMsg);
    }
}

export async function saveActivitie(form) {
    if (!form) {
        throw '5KJM357A';
    }

    return await apiFetchSave(form);
}
