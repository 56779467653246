var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {}, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "color": "#556ee6",
      "loader": "dots",
      "can-cancel": false,
      "is-full-page": false
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.showForm ? _c('section', {
    staticClass: "flex h-full flex-col overflow-hidden",
    staticStyle: {
      "min-height": "800px"
    }
  }, [_c('div', {
    staticClass: "flex-1  py-6"
  }, [_vm._m(0), _c('form', {
    staticClass: "form mt-5 px-2",
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitRegister.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 20 20",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "height": "20px",
      "width": "20px",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "7",
      "r": "4"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.name.$error
    },
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Nome completo *"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "mb-4 md:mb-0 relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
    }
  }), _c('polyline', {
    attrs: {
      "points": "22,6 12,13 2,6"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.email.$error
    },
    attrs: {
      "type": "text",
      "id": "email",
      "placeholder": "E-mail *"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone,
      expression: "form.phone"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: _vm.mask,
      expression: "mask"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.phone.$error
    },
    attrs: {
      "type": "text",
      "id": "phone",
      "placeholder": "Celular *"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 20 20",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "height": "20px",
      "width": "20px",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "7",
      "r": "4"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.role,
      expression: "form.role"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.role.$error
    },
    attrs: {
      "type": "text",
      "id": "role",
      "placeholder": "Cargo *"
    },
    domProps: {
      "value": _vm.form.role
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "role", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.company,
      expression: "form.company"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.company.$error
    },
    attrs: {
      "type": "text",
      "id": "company",
      "placeholder": "Nome da empresa *"
    },
    domProps: {
      "value": _vm.form.company
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "company", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.state.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300",
    attrs: {
      "id": "state",
      "tag-placeholder": "Estado *",
      "placeholder": "Estado  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": _vm.states,
      "multiple": false
    },
    on: {
      "select": _vm.setState
    },
    model: {
      value: _vm.form.state,
      callback: function ($$v) {
        _vm.$set(_vm.form, "state", $$v);
      },
      expression: "form.state"
    }
  })], 1), _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.city.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300",
    attrs: {
      "id": "city",
      "tag-placeholder": "Cidade *",
      "placeholder": "Cidade  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "nome",
      "track-by": "nome",
      "options": _vm.cities,
      "multiple": false
    },
    model: {
      value: _vm.form.city,
      callback: function ($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-row gap-2 mt-4"
  }, [_c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-500",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20px",
      "height": "20px",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.url_company,
      expression: "form.url_company"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.url_company.$error
    },
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "URL da empresa *"
    },
    domProps: {
      "value": _vm.form.url_company
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "url_company", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.client_nuvemshop.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "É cliente Nuvemshop ? *",
      "placeholder": "É cliente Nuvemshop ?  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": [{
        label: 'Sim',
        value: 'Sim'
      }, {
        label: 'Não',
        value: 'Não'
      }],
      "multiple": false
    },
    model: {
      value: _vm.form.client_nuvemshop,
      callback: function ($$v) {
        _vm.$set(_vm.form, "client_nuvemshop", $$v);
      },
      expression: "form.client_nuvemshop"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-row gap-2  mt-4"
  }, [_vm.form.client_nuvemshop && _vm.form.client_nuvemshop.value === 'Não' ? _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.plataform_client.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Qual plataforma usa ? *",
      "placeholder": "Qual plataforma usa?  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": _vm.plataformsE,
      "multiple": false
    },
    model: {
      value: _vm.form.plataform_client,
      callback: function ($$v) {
        _vm.$set(_vm.form, "plataform_client", $$v);
      },
      expression: "form.plataform_client"
    }
  })], 1) : _vm._e(), _vm.form.plataform_client && _vm.form.client_nuvemshop.value === 'Não' && _vm.form.plataform_client.value === 'Outra' ? _c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.plataform_client_other,
      expression: "form.plataform_client_other"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.plataform_client_other.$error
    },
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Informe qual plataforma usa? *"
    },
    domProps: {
      "value": _vm.form.plataform_client_other
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "plataform_client_other", $event.target.value);
      }
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "flex flex-row gap-2  mt-4"
  }, [_vm.form.client_nuvemshop && _vm.form.client_nuvemshop.value === 'Não' ? _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.revenue_plataform.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Faturamento mensal do e-commerce *",
      "placeholder": "Faturamento mensal do e-commerce  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": _vm.revenues,
      "multiple": false
    },
    model: {
      value: _vm.form.revenue_plataform,
      callback: function ($$v) {
        _vm.$set(_vm.form, "revenue_plataform", $$v);
      },
      expression: "form.revenue_plataform"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "flex items-start mb-2 mt-6"
  }, [_c('div', {
    staticClass: "flex items-center h-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.authorizes_data_usage,
      expression: "form.authorizes_data_usage"
    }],
    key: "",
    staticClass: "w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800",
    attrs: {
      "id": "authorizes_data_usage",
      "type": "checkbox",
      "required": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.form.authorizes_data_usage) ? _vm._i(_vm.form.authorizes_data_usage, null) > -1 : _vm.form.authorizes_data_usage
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.authorizes_data_usage,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "authorizes_data_usage", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "authorizes_data_usage", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "authorizes_data_usage", $$c);
        }
      }
    }
  })]), _vm._m(1)]), _c('div', {
    staticClass: "next-form py-3"
  }, [_c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "submit"
    }
  }, [_c('span', {
    staticClass: "flex justify-center mr-2"
  }, [_vm._v("Enviar inscrição")]), _c('svg', {
    staticClass: "feather feather-arrow-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "5",
      "y1": "12",
      "x2": "19",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 5 19 12 12 19"
    }
  })])])])])])]) : _vm._e(), _vm.showThanks ? _c('section', {
    staticClass: "flex justify-center"
  }, [_c('div', {
    staticClass: "w-full sm:w-full lg:w-full xl:w-full pt-10"
  }, [_c('div', {
    staticClass: "px-2 pt-2"
  }, [_vm._m(2), _c('div', {
    staticClass: "tittle mb-2 px-4 text-center"
  }, [_c('h1', {
    staticClass: "text-3259ce text-md font-bold mb-4"
  }, [_vm._v(_vm._s(_vm.participant.name) + ",")]), _c('h1', {
    staticClass: "text-616161 text-sm font-semibold"
  }, [_vm._v("Sua inscrição foi confirmada com sucesso!")])]), _vm._m(3)])])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle-register px-2"
  }, [_c('h1', {
    staticClass: "text-sm font-medium text-gray-600"
  }, [_vm._v("Preencha os campos abaixo para inscrever-se no evento:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "ml-2 text-sm font-medium text-gray-900",
    attrs: {
      "for": "authorizes_data_usage"
    }
  }, [_vm._v("Declaro que li e concordo com a "), _c('a', {
    staticClass: "text-blue-600 hover:underline dark:text-blue-500",
    attrs: {
      "href": "https://www.nuvemshop.com.br/politica-de-privacidade",
      "target": "_blank"
    }
  }, [_vm._v(" Política de privacidade ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center mb-4"
  }, [_c('img', {
    staticClass: "max-h-44 max-w-60 rounded-lg",
    attrs: {
      "src": require("../assets/sucess.png")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-2 mt-12 bg-white border-2 border-gray-200 rounded-lg"
  }, [_c('div', {
    staticClass: "mb-2 text-center px-4"
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-bold mb-4"
  }, [_vm._v("I M P O R T A N T E")]), _c('h1', {
    staticClass: "text-616161 text-sm font-normal"
  }, [_vm._v(" Em breve, você receberá um e-mail com todos os detalhes da sua participação, incluindo o "), _c('b', [_vm._v("QR Code")]), _vm._v(" necessário para o acesso ao evento. "), _c('br'), _c('br'), _vm._v(" Este é um encontro exclusivo, reunindo os maiores nomes do e-commerce da Nuvemshop em 2024. Prepare-se para uma experiência única, repleta de insights valiosos, oportunidades de networking e discussões inspiradoras sobre as tendências que estão moldando o futuro do comércio digital. "), _c('br'), _c('br'), _vm._v(" Fique atento à sua caixa de entrada. ")])])]);
}]

export { render, staticRenderFns }