<template>
  <Layout>
    <ConfirmaTerms />
  </Layout>
</template>

<script>
import ConfirmaTerms from "../components/confirmaTerms";
import Layout from "../components/layout";

export default {
  name: "confirma-inscricao",
  components: {Layout, ConfirmaTerms}
}
</script>

<style scoped>

</style>
