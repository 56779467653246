import Vue from 'vue'
import VTooltip from 'v-tooltip'
import Vuelidate from 'vuelidate'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'v-tooltip/dist/v-tooltip.css'
import './styles/index.css'

// import 'bootstrap';

Vue.config.productionTip = false
Vue.use(VTooltip);
Vue.use(Vuelidate)
Vue.use(Loading, {
  color: '#556ee6',
  loader: 'dots',
  canCancel: false,
  isFullPage: false
})
window.__app_version = '1.0.0-JIMLN5K4';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
