var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "color": "#556ee6",
      "loader": "dots",
      "can-cancel": false,
      "is-full-page": false
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.showError ? _c('section', {
    staticClass: "flex h-full flex-col overflow-hidden"
  }, [_c('div', {
    staticClass: "flex-1 overflow-hidden py-6"
  }, [_c('div', {
    staticClass: "subtitle-register px-2"
  }, [_c('h1', {
    staticClass: "text-md font-medium text-gray-600"
  }, [_c('b', [_vm._v(_vm._s(_vm.participant.name) + ",")])])]), _vm._m(0), _vm._m(1)])]) : _vm._e(), _vm.showPayment ? _c('section', {
    staticClass: "flex h-full flex-col overflow-hidden"
  }, [_c('div', {
    staticClass: "flex-1 overflow-hidden py-6"
  }, [_c('div', {
    staticClass: "subtitle-register px-2"
  }, [_c('h1', {
    staticClass: "text-md font-medium text-gray-600"
  }, [_vm._v("Olá, "), _c('b', [_vm._v(_vm._s(_vm.participant.name))])]), _c('h1', {
    staticClass: "mt-5 text-sm"
  }, [_vm._v("A sua vaga em nosso evento está quase garantida, confirme as informações de pagamento.")])]), _vm._m(2), _vm._m(3), _c('div', {
    staticClass: "absolute inset-x-0 bottom-10 p-3"
  }, [_vm._m(4), _c('div', {
    staticClass: "flex justify-between items-center mt-2 px-2"
  }, [_c('h1', {
    staticClass: "text-sm font-normal text-gray-600"
  }, [_vm._v("Cupom #ARCO100" + _vm._s(_vm.cupomRandom))]), _c('h1', {
    staticClass: "text-616161 text-sm font-medium"
  }, [_vm._v("100%")])]), _vm._m(5), _c('div', {
    staticClass: "next-form py-3 mt-5"
  }, [_c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-175376 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.stepTwo();
      }
    }
  }, [_c('span', {
    staticClass: "flex justify-center mr-2"
  }, [_vm._v("Prosseguir")]), _c('svg', {
    staticClass: "feather feather-arrow-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "5",
      "y1": "12",
      "x2": "19",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 5 19 12 12 19"
    }
  })])])])])])]) : _vm._e(), _vm.showAereoTermo ? _c('section', {
    staticClass: "flex h-full flex-col overflow-hidden"
  }, [_c('form', {
    staticClass: "form",
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitAereoTermo.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "flex-1 overflow-hidden py-6 px-2"
  }, [_c('div', {
    staticClass: "subtitle-register"
  }, [_c('h1', {
    staticClass: "text-md font-medium text-gray-600"
  }, [_c('b', [_vm._v(_vm._s(_vm.participant.name) + ",")])]), _c('h1', {
    staticClass: "mt-5 text-sm"
  }, [_vm._v("Para finalizar a sua inscrição, agora precisamos que você confirme os próximos passos.")])]), _vm._m(6), _c('div', {
    staticClass: "py-4 mt-4 bg-white border-dashed border-2 border-gray-300 rounded-lg cursor-pointer",
    on: {
      "click": _vm.showFile
    }
  }, [!_vm.aereoFile ? _c('div', {
    staticClass: "px-2 py-4 flex justify-center"
  }, [_c('svg', {
    attrs: {
      "width": "48",
      "height": "48",
      "stroke-width": "1.5",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 16L10 13L15.5 15.5",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 19H19M22 19H19M19 19V16M19 19V22",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]) : _vm._e(), _vm.aereoFile ? _c('p', {
    staticClass: "text-center text-xs font-medium mt-2 text-green-600"
  }, [_vm._v(_vm._s(_vm.aereoFile.name))]) : _vm._e(), !_vm.aereoFile ? _c('p', {
    staticClass: "text-center text-xs font-medium mt-2"
  }, [_vm._v("Clique aqui e anexe o seu bilhete aéreo")]) : _vm._e(), !_vm.aereoFile ? _c('p', {
    staticClass: "text-center text-xs font-normal mt-1"
  }, [_vm._v("PDF, JPG ou PNG")]) : _vm._e(), _c('input', {
    ref: "aereo",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "name": "aereo",
      "accept": "*/*"
    },
    on: {
      "change": _vm.setImage
    }
  })]), _vm._m(7), _vm._m(8), _vm._m(9), _vm._m(10), _c('div', {
    staticClass: "absolute inset-x-0 bottom-10 p-3"
  }, [_c('div', {
    staticClass: "next-form py-3 mt-5"
  }, [_c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-175376 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "submit"
    }
  }, [_c('span', {
    staticClass: "flex justify-center mr-2"
  }, [_vm._v("CONCLUIR")]), _c('svg', {
    staticClass: "feather feather-arrow-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "5",
      "y1": "12",
      "x2": "19",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 5 19 12 12 19"
    }
  })])])])])])])]) : _vm._e(), _vm.showThanks ? _c('section', {
    staticClass: "flex justify-center"
  }, [_c('div', {
    staticClass: "w-full sm:w-full lg:w-full xl:w-full pt-10"
  }, [_c('div', {
    staticClass: "px-2 pt-2"
  }, [_vm._m(11), _c('div', {
    staticClass: "tittle mb-2 px-4 text-center"
  }, [_c('h1', {
    staticClass: "text-3259ce text-md font-bold mb-4"
  }, [_vm._v(_vm._s(_vm.participant.name) + ",")]), _c('h1', {
    staticClass: "text-616161 text-sm font-semibold"
  }, [_vm._v("Parabéns! Sua inscrição foi concluída com sucesso. Em breve, você receberá seu QR CODE de acesso e orientações gerais do evento.")])]), _vm._m(12)])])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle-register px-2 mt-5"
  }, [_c('h1', {
    staticClass: "text-sm font-bold text-red-600"
  }, [_vm._v("AÇÃO NÃO PERMITIDA!")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4 px-2 mt-4 bg-red-300 border-2 border-red-500 rounded-lg ml-2 mr-2"
  }, [_c('div', {
    staticClass: "px-2"
  }, [_c('h1', {
    staticClass: "text-sm font-normal mb-1 text-red-900"
  }, [_vm._v("Identificamos que você já enviou a confirmação do bilhete aéreo e termos de compromisso.")]), _c('p', {
    staticClass: "mt-4 text-sm text-red-900"
  }, [_vm._v("Qualquer dúvida, você pode entrar em contato conosco através do email "), _c('b', [_vm._v("arcosuporte@fluireventos.com.br")]), _vm._v(" ou através do nosso número de "), _c('b', [_vm._v("Whatsapp (11) 98899-9900")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle-register px-2 mt-10"
  }, [_c('h1', {
    staticClass: "text-sm font-bold text-gray-600"
  }, [_vm._v("INGRESSOS DISPONÍVEIS")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4 px-2 mt-4 bg-white border-2 border-gray-300 rounded-lg ml-2 mr-2"
  }, [_c('div', {
    staticClass: "px-2"
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-bold mb-1"
  }, [_vm._v("Arco Day 2024 - Cortesia")]), _c('div', {
    staticClass: "flex gap-2"
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-medium text-red-600 line-through"
  }, [_vm._v("R$ 1.500,00")]), _vm._v(" - "), _c('h1', {
    staticClass: "text-616161 text-sm font-medium text-green-600"
  }, [_vm._v("R$ 0,00")])]), _c('div', {
    staticClass: "flex justify-between items-center mt-4"
  }, [_c('h1', {
    staticClass: "text-xs font-normal text-gray-600"
  }, [_vm._v("Expira em 4 dias")]), _c('div', {
    staticClass: "p-2 bg-gray-100 border-2 border-gray-300 rounded-md text-sm"
  }, [_vm._v(" CUPOM APLICADO ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between items-center mt-10 border-t-2 px-2"
  }, [_c('h1', {
    staticClass: "text-sm font-normal text-gray-600 mt-4"
  }, [_vm._v("Arco Day 2024 - Cortesia")]), _c('h1', {
    staticClass: "text-616161 text-sm font-medium mt-4"
  }, [_vm._v("R$ 1.500,00")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between items-center mt-2 px-2"
  }, [_c('h1', {
    staticClass: "text-sm font-bold text-gray-600"
  }, [_vm._v("Total a pagar")]), _c('h1', {
    staticClass: "text-616161 text-sm font-bold"
  }, [_vm._v("R$ 0,00")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-10"
  }, [_c('h1', {
    staticClass: "text-sm font-bold text-gray-600"
  }, [_vm._v("1. Faça upload do seu bilhete aéreo.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "text-sm font-normal text-red-600 mt-2"
  }, [_c('b', [_vm._v("Importante:")]), _vm._v(" Bilhete deve ser enviado em até 5 dias; após o prazo, sua inscrição será cancelada.")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-10"
  }, [_c('h1', {
    staticClass: "text-sm font-bold text-gray-600"
  }, [_vm._v("2. Termo de compromisso.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4 mt-4 bg-white border border-2 border-gray-300 rounded-lg"
  }, [_c('div', {
    staticClass: "px-4 py-1 max-h-40 overflow-auto text-xs"
  }, [_c('span', {
    staticClass: "text-xs text-justify mb-2"
  }, [_c('p', [_vm._v("TERMO DE COMPROMISSO DE COMPARECIMENTO AO EVENTO ARCO DAY")]), _c('br'), _c('p', [_vm._v("Por favor, leia atentamente os termos e condições abaixo estipulados para que você possa usufruir do ARCO DAY. O ARCO DAY é oferecido pela COMPANHIA BRASILEIRA DE EDUCAÇÃO E SISTEMAS DE ENSINO S.A. (“ARCO EDUCAÇÃO”).")]), _c('br'), _c('p', [_vm._v("Ao confirmar sua participação, a ESCOLA PARCEIRA ou CONVIDADO aceita as seguintes condições:")]), _c('br'), _c('h2', {
    staticClass: "font-bold"
  }, [_vm._v("DO EVENTO")]), _c('br'), _c('ul', [_c('li', [_vm._v("O ARCO DAY é um evento promovido pela ARCO EDUCAÇÃO, destinado exclusivamente às escolas parceiras ou convidadas nominalmente pela empresa. O objetivo do evento é revolucionar o mercado educacional com palestras de especialistas nacionais e internacionais que vão proporcionar aprendizado, networking, insights e descobertas.")]), _c('br'), _c('li', [_vm._v("O evento será realizado no WTC Events – Golden Hall com endereço na Avenida das Nações Unidas, nº 12551, Brooklin Novo, São Paulo – SP nas datas de 18 e 19 de junho. No dia 18 o evento será realizado no período das 08h às 19h e no dia 19 no período das 08h às 14h.")])]), _c('br'), _c('h2', {
    staticClass: "font-bold"
  }, [_vm._v("DO COMPROMISSO DE COMPARECIMENTO")]), _c('br'), _c('ul', [_c('li', [_vm._v("A ESCOLA PARCEIRA ou CONVIDADO se compromete a garantir a participação de seu representante no ARCO DAY, mediante a inscrição nominal, realizada previamente no website ("), _c('a', {
    attrs: {
      "href": "https://arcoday.eventfy.live/"
    }
  }, [_vm._v("https://arcoday.eventfy.live/")]), _vm._v(").")]), _c('br'), _c('li', [_vm._v("A justificativa para eventual desistência deverá ser formalmente comunicada à ARCO EDUCAÇÃO através do e-mail "), _c('a', {
    attrs: {
      "href": "mailto:arcoday@arcoeducacao.com.br"
    }
  }, [_vm._v("arcoday@arcoeducacao.com.br")]), _vm._v(" para que sua vaga possa ser alocada para uma nova ESCOLA PARCEIRA ou CONVIDADO.")])]), _c('br'), _c('h2', {
    staticClass: "font-bold"
  }, [_vm._v("DO PAGAMENTO")]), _c('br'), _c('ul', [_c('li', [_vm._v("O valor referente ao ingresso é de R$ 1.500,00 (mil e quinhentos reais), podendo a ARCO EDUCAÇÃO optar por oferecer gratuidade à ESCOLA PARCEIRA ou CONVIDADO, que será devidamente demonstrado no ato da inscrição;")]), _c('br'), _c('li', [_vm._v("Toda a logística e alimentação, bem como qualquer outro custo de consumo, deverá ser arcado pela ESCOLA PARCEIRA ou CONVIDADO.")])]), _c('br'), _c('h2', {
    staticClass: "font-bold"
  }, [_vm._v("DAS DISPOSIÇÕES GERAIS")]), _c('br'), _c('ul', [_c('li', [_vm._v("Este Termo de Compromisso entra em vigor no ato da inscrição para o ARCO DAY e é válido até o término do evento.")]), _c('br'), _c('li', [_vm._v("O tratamento dos dados pessoais coletados dos visitantes do website do ARCO DAY e daqueles que se inscreverem e participarem do evento se dará nos termos da "), _c('a', {
    attrs: {
      "href": "https://dpo.privacytools.com.br/api/policy/pdf/dczJJdxMNONPCBrINjyTVh08EveZXzbKJQQPTIoe4xAIhgflpuxYqsodFpxFJVAb2mA48pUERLyXh4kUVS9RXQ__equal____equal__",
      "target": "_blank"
    }
  }, [_vm._v("Declaração de Privacidade do ARCO DAY")]), _vm._v(".")]), _c('br'), _c('li', [_vm._v("A ESCOLA PARCEIRA ou CONVIDADO declara estar ciente de todas as condições estabelecidas neste Termo e concorda em cumpri-las integralmente.")])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex mb-6 mt-6 items-center"
  }, [_c('label', {
    staticClass: "ml-2 text-sm font-normal text-gray-500",
    attrs: {
      "for": "authorizes_data_usage"
    }
  }, [_vm._v("Ao clicar em "), _c('b', [_vm._v("CONCLUIR")]), _vm._v(" eu declaro que li e concordo com os termos de compromisso")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center mb-4"
  }, [_c('img', {
    staticClass: "max-h-44 max-w-60 rounded-lg",
    attrs: {
      "src": require("../assets/sucess.png")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-2 mt-12 bg-white border-2 border-gray-200 rounded-lg"
  }, [_c('div', {
    staticClass: "mb-2 text-center px-4"
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-bold mb-4"
  }, [_vm._v("I M P O R T A N T E")]), _c('h1', {
    staticClass: "text-616161 text-sm font-normal"
  }, [_vm._v("Fique atento ao seu e-mail de inscrição, pois é por meio dele que você receberá todas as informações necessárias.")])])]);
}]

export { render, staticRenderFns }