var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Content'), _c('Drawer', {
    attrs: {
      "align": 'right',
      "closeable": true,
      "maskClosable": false,
      "zIndex": 100002
    },
    on: {
      "close": _vm.openModalTermo
    }
  }, [_vm.openTermo ? _c('div', [_c('div', {
    staticClass: "cursor-pointer text-gray-600 w-full items-center border-b border-t-neutral-700 border-solid px-2 pb-4"
  }, [_c('h2', {
    staticClass: "text-sm font-semibold text-gray-900",
    attrs: {
      "id": "slide-over-title"
    }
  }, [_vm._v(_vm._s(_vm.titleTermo))])]), _c('Termo', {
    attrs: {
      "participant": _vm.participant
    },
    on: {
      "headerDrawerTermo": _vm.updateTitle
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }