<template>
  <main class="">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <section class="flex h-full flex-col overflow-hidden" style="min-height: 800px" v-if="showForm">
      <div class="flex-1  py-6">
        <div class="subtitle-register px-2">
          <h1 class="text-sm font-medium text-gray-600">Preencha os campos abaixo para inscrever-se no evento:</h1>
        </div>
        <form @submit.prevent="submitRegister" class="form mt-5 px-2" autocomplete="off">
          <div class="block md:flex flex-row gap-2">
            <div class="relative my-4" style="width: 100%">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 20 20" stroke-linecap="round"
                  stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-gray-500">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <input type="text" id="name"
                v-model="form.name"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Nome completo *"
                :class="{ 'border-red-500': submitted && $v.form.name.$error }"
                >
            </div>
          </div>
          <div class="block md:flex flex-row gap-2">
            <div class="mb-4 md:mb-0 relative" style="width:100%">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                  stroke-linejoin="round" class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </div>
              <input type="text" id="email"
                v-model="form.email"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="E-mail *"
                :class="{ 'border-red-500': submitted && $v.form.email.$error }"
                >
            </div>
            <div class="relative" style="width:100%">
             <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg stroke="currentColor" class="w-5 h-5 text-gray-500" fill="none" stroke-width="2" viewBox="0 0 24 24"
                  stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                  </path>
                </svg>
              </div>
              <input type="text" id="phone"
                v-model="form.phone"
                v-mask="mask"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Celular *"
                :class="{ 'border-red-500': submitted && $v.form.phone.$error }"
                >
            </div>
          </div>
          <div class="flex flex-row gap-2">
            <div class="relative my-4" style="width: 100%">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 20 20" stroke-linecap="round"
                  stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-gray-500">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <input type="text" id="role"
                v-model="form.role"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Cargo *"
                :class="{ 'border-red-500': submitted && $v.form.role.$error }"
                >
            </div>
            <div class="relative my-4" style="width: 100%">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
                </svg>
              </div>
              <input type="text" id="company"
                v-model="form.company"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Nome da empresa *"
                :class="{ 'border-red-500': submitted && $v.form.company.$error }"
                >
            </div>
          </div>
          <div class="flex flex-row gap-2">
            <div class="relative" :class="{ 'invalid-select': submitted && $v.form.state.$error }" style="width: 100%">
              <multiselect
                id="state"
                tag-placeholder="Estado *"
                placeholder="Estado  *"
                v-model="form.state"
                select-label="Selecione uma opção"
                selectedLabel="Selecionado"
                deselectLabel="Desmarcar"
                label="label"
                track-by="label"
                class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300"
                :options="states"
                @select="setState"
                :multiple="false">
              </multiselect>
            </div>
            <div class="relative" :class="{ 'invalid-select': submitted && $v.form.city.$error }" style="width: 100%">
              <multiselect
                id="city"
                tag-placeholder="Cidade *"
                placeholder="Cidade  *"
                select-label="Selecione uma opção"
                selectedLabel="Selecionado"
                deselectLabel="Desmarcar"
                v-model="form.city"
                label="nome"
                track-by="nome"
                class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300"
                :options="cities"
                :multiple="false">
              </multiselect>
            </div>
          </div>

          <div class="flex flex-row gap-2 mt-4">
            <div class="relative" style="width: 100%">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg class="w-6 h-6 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                </svg>
              </div>
              <input type="text" id="name"
                v-model="form.url_company"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="URL da empresa *"
                :class="{ 'border-red-500': submitted && $v.form.url_company.$error }"
                >
            </div>
            <div class="relative" :class="{ 'invalid-select': submitted && $v.form.client_nuvemshop.$error }" style="width: 100%">
              <multiselect
                tag-placeholder="É cliente Nuvemshop ? *"
                placeholder="É cliente Nuvemshop ?  *"
                v-model="form.client_nuvemshop"
                select-label="Selecione uma opção"
                selectedLabel="Selecionado"
                deselectLabel="Desmarcar"
                label="label"
                track-by="label"
                class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                :options="[{label: 'Sim', value: 'Sim'}, {label: 'Não', value: 'Não'}]"
                :multiple="false">
              </multiselect>
            </div>
          </div>
          <div class="flex flex-row gap-2  mt-4">
            <div class="relative" :class="{ 'invalid-select': submitted && $v.form.plataform_client.$error }" style="width: 100%" v-if="form.client_nuvemshop && form.client_nuvemshop.value === 'Não'">
              <multiselect
                tag-placeholder="Qual plataforma usa ? *"
                placeholder="Qual plataforma usa?  *"
                v-model="form.plataform_client"
                select-label="Selecione uma opção"
                selectedLabel="Selecionado"
                deselectLabel="Desmarcar"
                label="label"
                track-by="label"
                class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                :options="plataformsE"
                :multiple="false">
              </multiselect>
            </div>
            <div class="relative" style="width: 100%" v-if="form.plataform_client && form.client_nuvemshop.value === 'Não' && form.plataform_client.value === 'Outra'">
              <input type="text" id="name"
                v-model="form.plataform_client_other"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Informe qual plataforma usa? *"
                :class="{ 'border-red-500': submitted && $v.form.plataform_client_other.$error }"
                >
            </div>
          </div>
          <div class="flex flex-row gap-2  mt-4">
            <div class="relative" style="width: 100%" v-if="form.client_nuvemshop && form.client_nuvemshop.value === 'Não'" :class="{ 'invalid-select': submitted && $v.form.revenue_plataform.$error }">
              <multiselect
                tag-placeholder="Faturamento mensal do e-commerce *"
                placeholder="Faturamento mensal do e-commerce  *"
                v-model="form.revenue_plataform"
                select-label="Selecione uma opção"
                selectedLabel="Selecionado"
                deselectLabel="Desmarcar"
                label="label"
                track-by="label"
                class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                :options="revenues"
                :multiple="false">
              </multiselect>
            </div>
          </div>
          <div class="flex items-start mb-2 mt-6">
            <div class="flex items-center h-5">
              <input id="authorizes_data_usage" v-model="form.authorizes_data_usage" type="checkbox" key="" class="w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                required>
            </div>
            <label for="authorizes_data_usage" class="ml-2 text-sm font-medium text-gray-900">Declaro que li e concordo com a <a
                href="https://www.nuvemshop.com.br/politica-de-privacidade" target="_blank" class="text-blue-600 hover:underline dark:text-blue-500"> Política de privacidade
                </a></label>
          </div>
          <div class="next-form py-3">
            <button
              type="submit"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline">
              <span class="flex justify-center mr-2">Enviar inscrição</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </section>
    <section class="flex justify-center" v-if="showThanks">
      <div class="w-full sm:w-full lg:w-full xl:w-full pt-10">
        <div class="px-2 pt-2">
          <div class="flex justify-center mb-4">
            <img class="max-h-44 max-w-60 rounded-lg" src="../assets/sucess.png">
          </div>
          <div class="tittle mb-2 px-4 text-center">
            <h1 class="text-3259ce text-md font-bold mb-4">{{ participant.name }},</h1>
            <h1 class="text-616161 text-sm font-semibold">Sua inscrição foi confirmada com sucesso!</h1>
          </div>
          <div class="py-2 mt-12 bg-white border-2 border-gray-200 rounded-lg">
            <div class="mb-2 text-center px-4">
              <h1 class="text-616161 text-sm font-bold mb-4">I M P O R T A N T E</h1>
              <h1 class="text-616161 text-sm font-normal">
                Em breve, você receberá um e-mail com todos os detalhes da sua participação, incluindo o <b>QR Code</b> necessário para o acesso ao evento.
                <br />
                <br />
                Este é um encontro exclusivo, reunindo os maiores nomes do e-commerce da Nuvemshop em 2024. Prepare-se para uma experiência única, repleta de insights valiosos, oportunidades de networking e discussões inspiradoras sobre as tendências que estão moldando o futuro do comércio digital.
                <br />
                <br />
                Fique atento à sua caixa de entrada.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import {register} from "@/services/api/register";
import { consultaCities } from "@/services/api/consultaCities";
import {notification} from "@/services/notification";
import { required, email } from 'vuelidate/lib/validators'
import { states } from '@/services/toolbox/states'
import Multiselect from 'vue-multiselect'
import { url } from 'vuelidate/lib/validators';

export default {
  name: "form-f1",
  components: { Loading, Multiselect },
  directives: {
    mask: VueMaskDirective
  },
  data: () => ({
    open: false,
    mask: ['(', /\d/, /\d/, ') ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    showModal: false,
    revenues: [
      { label: 'até 50 mil', value: 'até 50 mil' },
      { label: 'entre 50 e 100 mil', value: 'entre 50 e 100 mil' },
      { label: 'entre 100 e 400 mil', value: 'entre 100 e 400 mil' },
      { label: 'acima de 400 mil', value: 'acima de 400 mil' },
    ],
    plataformsE: [
      { label: 'Shopify', value: 'Shopify' },
      { label: 'VTEX', value: 'VTEX' },
      { label: 'Magento', value: 'Magento' },
      { label: 'Wix', value: 'Wix' },
      { label: 'Linx', value: 'Linx' },
      { label: 'Tray', value: 'Tray' },
      { label: 'Loja Integrada', value: 'Loja Integrada' },
      { label: 'Outra', value: 'Outra' },
    ],
    states: states,
    cities: [],
    loading: true,
    submitted: false,
    showForm: true,
    showThanks: false,
    participant: {},
    form: {
      name: null,
      phone: null,
      email: null,
      company: null,
      role: null,
      url_company: 'https://',
      state: null,
      city: null,
      client_nuvemshop: null,
      plataform_client: null,
      plataform_client_other: null,
      authorizes_data_usage: null
    },
    formIsDisabled: false,
  }),
  validations () {
    let valid = {
      email: {
        required,
        email
      },
      name: { required },
      phone: { required },
      role: { required },
      company: { required },
      url_company: { required, url },
      state: { required },
      city: { required },
      client_nuvemshop: { required },
    }
    if (this.form.client_nuvemshop && this.form.client_nuvemshop.value === 'Não') {
      valid = {
        ...valid,
        plataform_client: { required },
        revenue_plataform: { required },
      }
      if (this.form.plataform_client && this.form.plataform_client.value === 'Outra') {
        valid = {...valid, plataform_client_other: { required }}
      }
    }
    return { form: valid }
  },
  destroyed () {
    document.body.style.overflow = "visible";
  },
  mounted () {
    document.body.style.overflow = "hidden";
    this.loading = false;
  },
  methods: {
    setState (option) {
      if (option.value) {
        this.getCities(option.value)
        return
      }
    },
    async getCities (uf) {
      this.cities = [];
      const respCities = await consultaCities(uf);
      if (!respCities) return
      this.cities = respCities.cities
    },
    toggle() {
      this.openRightDrawer = !this.openRightDrawer;
    },
    async submitRegister () {
      try {
        this.loading = true;
        let resp;
        this.submitted = true
        this.loading = true;
        this.$v.form.$touch()

        if (this.$v.form.$invalid) {
          this.loading = false;
          return
        }
        console.log(this.form)
        // throw new Error('Interrompendo o script aqui');
        // eslint-disable-next-line no-unreachable
        resp = await register(this.form);
        this.participant = resp;
        this.showForm = false;
        this.showThanks = true;
        this.loading = false;
        this.$v.$reset();
      } catch (err) {
        notification.error(err?.errMsg ?? 'Houve um problema para concluir inscrição');
        console.log(err)
        this.loading = false;
        return;
      }
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__select {
  height: 50px !important;
}
.multiselect__tags {
  padding: 16px 40px 0 16px !important;
  min-height: 54px !important;
}
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}
.bg-inactive {
  background: #ededed;
}
.invalid-select {
  border: 1px solid #ee4648 !important;
  border-radius: 4px;
}
</style>
